
        @import "@/styles/griddle-overrides.scss";
        @import "@braid/griddle/scss/griddle.scss";
        

































































































@import '@/styles/_colors.scss';

.notes {
  display: flex;
  flex-direction: column;
}

.notes--margin-bottom {
  margin-bottom: 1rem;
}

.notes__markdown {
  padding: 0.75rem 0.75rem 0.6rem 0rem;
}

.notes__btn {
  display: flex;
  flex-direction: row;
}
